<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-11-29 11:08:03
 * @FilePath: \yda_web_manage\src\components\BreadCrumb\index.vue
 * @Description: 面包屑
 * 
-->
<template>
  <section class="block-breadcrumb">
    <a-breadcrumb>
      <a-breadcrumb-item v-for="(item, index) in breadList" :key="item" style="font-size: 14px">
        <span
          class="item"
          v-if="index == breadList.length - 1"
          :style="index == breadList.length - 1 ? 'color: rgba(0,0,0,0.65)' : ''"
          >{{ item }}</span
        >
        <!-- <span v-else-if="index == 0">{{ item }}</span> -->
        <!-- 兼容电子围栏 -->
        <!-- <template v-else-if="index == 0">
          <router-link v-if="$route.path === '/sealManage/fence'" :to="pageRouter[item]">
            {{ item }}
          </router-link>

          <span v-else>{{ item }}</span>
        </template>
        <router-link v-else :to="pageRouter[item]">
          {{ item }}
        </router-link> -->
        <template v-else>
          <router-link v-if="pageRouter[item]" :to="pageRouter[item]">
            {{ item }}
          </router-link>
          <span v-else>{{ item }}</span>
        </template>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <p class="breadcrumb-title">{{ title }}</p>
  </section>
</template>

<script>
import { ref, watch, defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { pageTitle } from './pageTitle'
export default defineComponent({
  name: 'bread-crumb',
  setup() {
    const $route = useRoute()
    const breadList = ref([])
    const state = reactive({
      pageTitle,
      title: '',
      pageRouter: {
        用印申请: '/seal/apply',
        用印记录: '/seal/applyList',
        用印审批: '/approval/approved',
        抄送我的: '/approval/informPeople',
        印章管理: '/sealManage',
        终端管理: '/equipmentManage',
        组织架构: '/businessManage/staff',
        角色管理: '/businessManage/role',
        流程名称管理: '/businessManage/business',
        指纹管理: '/businessManage/fingerprint',
        // TODO：消息管理
        消息管理: '/businessManage/message',
        操作日志: '/businessManage/operationLog',
        归档管理: '/archiveManage',
        待用印:'/seal/applyWaitList',
        已完成:'/seal/applyCompleteList',
        已归档:'/seal/applyArchiveList',
        待审批:'/approval/notApproval',
        已审批:'/approval/alreadyApproval',
        
      }
    })
    watch(
      () => $route.fullPath,
      () => {
        breadList.value = []
        // if ($route.path === '/seal/applyList/detail') {
        //   $route.matched[2].meta.title = '用印记录,' + ($route.query.id ? '常规用印详情' : '指纹用印详情')
        // }
        // console.log('面包屑', breadList.value, $route.matched)
        $route.matched.slice(1).forEach(item => {
          item.meta.title && breadList.value.push(...item.meta.title.split(','))
        })
        state.title = pageTitle[$route.path]
      },
      { immediate: true }
    )
    return {
      breadList,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.block-breadcrumb {
  background-color: #ffffff;
  padding-left: 24px;
  padding-bottom: 24px;

  .item {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 56px;
    display: inline-block;
    height: 56px;
  }

  .breadcrumb-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
