import { notification } from 'ant-design-vue'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router/config-router'
import { SET_ROUTER } from '@/store/router/mutations-type'
import { SET_PERMISSION_ERROR_NUM, REST_PERMISSION_ERROR_NUM } from '../store/user/mutations-type'
import { environmentJudge } from '@/utils/utils.js'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: 'https://ydatestapi.libawall.com',
  // baseURL: 'https://testydaapi1.libawall.com/',
  // baseURL: 'https://testydaapi2.libawall.com/',
  // baseURL: 'https://uatapi.libawall.com',
  // baseURL: 'https://frp.dmxiaoshen.me',
  // baseURL: 'http://192.168.52.80:18088',
  withCredentials: true,
  timeout: 10 * 60 * 1000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = error => {
  console.warn('请求异常:' + error)
  return Promise.reject(error)
}

function objToFormData(obj) {
  const form = new FormData()
  Object.keys(obj).forEach(item => {
    obj[item] !== undefined && obj[item] !== null && form.set(item, obj[item])
  })
  return form
}

//退登逻辑
const logout = ({ code, message }) => {
  // 登录超市 携带参数退出 跳转到登录页
  if (localStorage.getItem('yda-admin-userInfo')) {
    ;+code !== 0 &&
      notification['error']({
        message: '提示',
        description: message || '服务器未知错误'
      })
  }
  localStorage.removeItem('yda-admin-userInfo')
  localStorage.removeItem('yda-qywx-sessionId')
  localStorage.removeItem('checkedRows')
  localStorage.removeItem('paginationParams')
  // 是否企业微信
  const isMobile = window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  ) // 是否手机端
  const environment = environmentJudge()
  console.log('isMobile', isMobile)
  //清除用印管理缓存的条件
  store.commit('sealManage/CLEAECACHE')

  if (environment != 'OTHERS' && !isMobile) {
    window.location.href = `${process.env.VUE_APP_QYWX_URL}?isFailPC=1`
  } else {
    router.push(`/login`)
  }
}

//重新刷新菜单
const reloadMenus = async ({ code, message }) => {
  ;+code !== 0 &&
    notification['error']({
      message: '提示',
      description: message || '服务器未知错误'
    })
  await new Promise(resolve => {
    store.dispatch(`router/${SET_ROUTER}`, resolve)
  })
  if (store.state.router.navMenus && store.state.router.navMenus.length) {
    const navMenus = store.state.router.navMenus
    router.push(navMenus[0].path)
  } else {
    router.push(`/login`)
  }
}

// request interceptor
request.interceptors.request.use(config => {
  const types = ['post', 'put', 'delete']
  const { params = {}, method, data } = config
  const sessionId = localStorage.getItem('yda-qywx-sessionId')

  if (sessionId != 'null' && sessionId) {
    config.headers.sessionId = sessionId
  }
  if (types.includes(method.toLowerCase())) {
    !data ? (config.data = objToFormData(params)) : null
    delete config.params
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(async response => {
  // 下载中
  if (response.headers['is-download']) {
    notification['success']({
      message: '提示',
      description: '下载成功'
    })
    return response
  }
  const { code, message } = response.data
  // 当code返回失败时 抛出错误提示
  if (+code === 601 || +code === 613) {
    logout({ code, message })
  } else if (+code == 406) {
    //权限不足重试次数
    const permissionErrorNum = store.state.user.permissionErrorNum || 0
    const maxNum = store.state.user.maxNum || 1
    if (permissionErrorNum >= maxNum) {
      store.commit(`user/${REST_PERMISSION_ERROR_NUM}`)
      logout({ code, message })
    } else {
      store.commit(`user/${SET_PERMISSION_ERROR_NUM}`)
      await reloadMenus({ code, message })
    }
  } else if (+code === 401 || +code === 403) {
    await reloadMenus({ code, message })
  } else {
    if (
      (response.config.url === '/pc/apply/submit' ||
        response.config.url === '/pc/apply/reApply' ||
        response.config.url.includes('pc/apply/reApply/check')) &&
      (code === 8003 || code === 8004 || code === 8006 || code === 8009 || code === 8019)
    ) {
      return response.data || []
    }
    if (
      response.config.url.includes('pc/workspace/process/info') &&
      (code === 8003 || code === 8004 || code === 8006 || code === 8010 || code === 8013)
    ) {
      return response.data || []
    }
    if (response.config.url.includes('pc/flowable/checkName')) {
      return response.data || []
    }
    // 2112:登录重置密码的报错
    ;+code !== 0 &&
      +code !== 2112 &&
      notification['error']({
        message: '提示',
        description: message || '服务器未知错误'
      })
  }

  return response.data || []
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request // 局部使用

export { installer as VueAxios, request as axios } // 全局使用
