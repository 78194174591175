import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const enums = {
  namespaced: true,
  state: {
    list: JSON.parse(localStorage.getItem('enums')) || []
  },
  getters,
  actions,
  mutations
}

export default enums
