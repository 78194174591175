import router from '@/router/config-router'

export default app => {
  app.directive('btn', {
    // 是否显示页面页面中的的按钮
    mounted(el, binding) {
      const { value } = binding
      if (Array.isArray(router.currentRoute.value.meta.permission)) {
        const arr = value.split(',')
        const arr2 = arr.filter(it => {
          return !!router.currentRoute.value.meta.permission.find(item => {
            // if (item.includes(it.toUpperCase())) {
            // }
            return item.includes(it.toUpperCase())
          })
        })
        !arr2.length == arr.length
          ? el.parentNode
            ? el.parentNode.removeChild(el)
            : (el.style.display = 'none')
          : null
      }
    }
  })
}
