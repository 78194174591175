<template>
  <a-config-provider :locale="locale" >
    <router-view />
  </a-config-provider>
</template>
<script>
import locale from 'ant-design-vue/es/locale/zh_CN.js'
import { useStore } from 'vuex'
import { SET_ROOT_SIZE } from '@/store/globalData/mutations-type'
import { throttle } from '@/utils/utils'
import { onMounted, onUnmounted } from '@vue/runtime-core'
export default {
  setup() {
    const store = useStore()
    const setHTMLFontSizeFn = throttle(() => {
      const clientWidth = document.body.clientWidth
      const rootSize = (clientWidth / 120).toFixed(1)
      document.documentElement.style.fontSize = rootSize + 'px'
      store.commit(`globalData/${SET_ROOT_SIZE}`, rootSize)
    }, 16)

    onMounted(() => {
      setHTMLFontSizeFn()
      window.addEventListener('resize', setHTMLFontSizeFn, false)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setHTMLFontSizeFn)
    })

    // 监听关闭
    window.addEventListener('beforeunload', (e) => beforeUnloadEvent(e))
    const beforeUnloadEvent = (e) => {
      const isMobile = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) // 是否手机端
      const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
      if (isComWx && !isMobile) {
        //PC端企业微信
        console.log('企业微信销毁啦')
        localStorage.removeItem('yda-admin-userInfo')
        localStorage.removeItem('checkedRows')
        localStorage.removeItem('paginationParams')
        return 'com-wx-pc'
      }
    }

    return {
      setHTMLFontSizeFn
    }
  },
  created() {
    // this.setHTMLFontSizeFn()
  },
  data() {
    return {
      locale,
    }
  },
}
</script>
<style lang="scss"></style>
