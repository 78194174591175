<template>
  <a-modal
    centered
    destroyOnClose
    :zIndex="1000"
    :closable="false"
    :footer="null"
    :width="600"
    :bodyStyle="{
      position: 'relative',
      padding: '184px  40px  48px',
      backgroundImage: `url(${require('@/assets/images/notice-bg.png')})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }"
  >
    <div>
      <img class="notice-decoration" :src="require('@/assets/images/notice-decoration.png')" alt="" srcset="" />
      <p class="notice-title">尊敬的用户您好：</p>
      <p class="notice-text">
        <span style="display: block;"
          >为了提供更<i class="redText">优质的服务</i>，我们对产品做了一版<i class="redText">重大升级</i
          >，主要功能点如下：</span
        >
        <span class="notice-content" style="display: block;">
          <p>1. 通过数据权限管控集团内用户的业务数据可见范围，实现更灵活的权限管控方式</p>
          <p>2. 对功能权限和操作权限进行重新组合，支持更加简洁和高效的功能权限分配方式</p>
          <p>3. 将参数配置迁移至流程名称和终端管理中，支持企业更细维度的流程管控需求</p>
        </span>
        <span style="display: block;">
          如您在体验新版本时遇到任何问题，请<i class="redText">第一时间联系我们</i>的客服售后人员，我们将竭诚为您服务。
        </span>
      </p>
    </div>
    <a-button class="ikonwBtn" @click="handleCancel">我知道了</a-button>
  </a-modal>
</template>

<script>
export default {
  name: 'NoticeModal',
  props: {},
  methods: {
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-decoration {
  position: absolute;
  top: 64px;
  width: 560px;
  left: 20px;
}
.notice-title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-bottom: 16px;
}
.notice-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
  margin-bottom: 32px;
  .redText {
    font-style: normal;
    color: #c3161cff;
  }
}
.ikonwBtn {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 32px;
  background: #c3161c;
  border-radius: 2px;
  color: #ffffff;
  border: none;
  box-shadow: none;
}

.notice-content {
  p {
    text-indent: 6px;
  }
}
</style>
