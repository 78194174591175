// 刘志坤-V2.3.0

<template>
  <div class="bottom-copyright">
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.bottom-copyright {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  width: 100%;
  text-align: center;
  .copyright-icon {
    font-size: 14px;
    margin: 0 2px 0 2px;
  }
}
</style>
