// const axios = require('axios')
import axios from 'axios'
const requestUrl = 'https://log.libawall.com'
const system = 'Browser' // system: Browser, iOS, Android, Mini
const userAgent = window.navigator.userAgent
const project = '智慧印章管理后台'
// eslint-disable-next-line no-unused-vars
function xlog(app, params = {}) {
  // 请提前将用户信息存入storage， 否则需要传入
  const userId = params.userId || localStorage.getItem('userId') || ''
  const sessionId = params.sessionId || localStorage.getItem('sessionId') || ''
  // 捕获内容：
  // 组件渲染器
  // 事件处理器
  // 生命周期钩子
  // setup() 函数
  // 侦听器
  // 自定义指令钩子
  // 过渡 (Transition) 钩子
  app.config.errorHandler = (err, instance, info) => {
    // 处理错误，例如：报告给一个服务
    axios.post(requestUrl, {
      project: project, // 项目名称，可以用中文
      system,
      level: params.level || 'DEBUG', //DEBUG, INFO, WARN, ERROR
      userId,
      sessionId,
      userAgent,
      version: params.version, // 当前版本号
      content: JSON.stringify({
        error: err.stack,
        info: info
      })
    })
  }
  // app.config.warnHandler = (msg, instance, trace) => {
  //   // `trace` is the component hierarchy trace
  // }
}

function logPost(params = {}) {
  // 请提前将用户信息存入storage， 否则需要传入
  const userId = params.userId || localStorage.getItem('userId') || ''
  const sessionId = params.sessionId || localStorage.getItem('sessionId') || ''
  axios.post(requestUrl, {
    project: project, // 项目名称，可以用中文
    system,
    level: params.level || 'DEBUG', //DEBUG, INFO, WARN, ERROR
    userId,
    sessionId,
    userAgent,
    version: params.version, // 当前版本号
    content: JSON.stringify(params.info) // 传入你想上报的内容
  })
}

export { xlog, logPost }
