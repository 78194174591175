<!-- /*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-05-05 16:31:47
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-05-05 18:16:00
 * @FilePath: \yda_web_manage\src\router\async-router.js
 * @Description:左侧菜单配置
 *
 */ -->
<script>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted, onUpdated, reactive, watch } from 'vue'
import Menu from 'ant-design-vue/es/menu'
// import newModalVue from '../../views/approval/components/newModal.vue'
const { Item, SubMenu } = Menu
const iconPathObj = {
  dashboard: 'icon-gongzuotai',
  seal: 'icon-yongyinguanli1',
  approval: 'icon-shenpizhongxin',
  sealManage: 'icon-yinzhangguanli',
  businessManage: 'icon-qiyeguanli',
  dataCenter: 'icon-a-shuju2',
  messageCenter: 'icon-xiaoxi',
  elecSeal: 'icon-eleSeal',
  selfHelp: 'icon-yongyinshenqingzongshu',
  equipmentManage: 'icon-shebeiguanli',
  archiveManage: 'icon-guidangguanli',
  xs: 'icon-wenjianguanli'
}

//刷选条件白名单
const whitePath = ['seal', 'applyList', 'apply']

export default {
  data() {
    return {}
  },
  setup() {
    const $store = useStore()
    const $router = useRouter()
    const $route = useRoute()
    const state = reactive({
      navData: computed(() => $store.state.router.navMenus),
      openKeys: [],
      selectedKeys: [],
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).enterpriseId
    })

    //清除缓存的筛选条件
    const clear = key => {
      if (!whitePath.includes(key) && key) {
        $store.commit('sealManage/CLEAECACHE')
      }
    }
    let routeList = []
    let stagingKey // 暂存key
    const openChange = keys => {
      // 菜单展开回调
      // state.openKeys = keys[keys.length - 1] === state.openKeys[0] ? [] : [keys[keys.length - 1]]
      routeList = []

      recursionKeyRoute(state.navData, keys[keys.length - 1])
      //state.openKeys 当前展开的submenukey
      //keys 此次操作后最终展开的submenukey
      //获取此次操作完成后最晚展开的 submenukey
      //latestOpenKey ： 某个一级submenu的子submenu｜一级submenu
      const latestOpenKey = keys.find(key => state.openKeys.indexOf(key) === -1)
      //获取最外层根节点key
      const rootSubmenuKeys = state.navData.map(item => item.key)
      //获取此次操作完成后最晚展开的 submenukey不属于最外层（只可能是其父级的子集）
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        //直接取keys
        state.openKeys = keys
      } else {
        //打开或关闭在外层submenu（可能是关闭自己｜也可能是打开其他一级submenu）latestOpenKey：要展开
        state.openKeys = latestOpenKey ? [latestOpenKey] : []
      }

      if (keys.length) {
        clear(state.openKeys[state.openKeys.length - 1])
        let path = recursionMenuPath(state.navData, keys[keys.length - 1])
        state.selectedKeys = [stagingKey].concat(routeList)
        $router.push({ path })
      }
    }

    // 递归获取当前key的所属路径
    const recursionKeyRoute = (data, key) => {
      for (const item of data) {
        routeList.push(item.key)
        if (item.key == key) return true
        if (!item.children) {
          routeList.splice(routeList.length - 1, 1)
          continue
        }
        if (item.children) {
          if (!recursionKeyRoute(item.children, key)) {
            routeList.splice(routeList.length - 1, 1)
          } else {
            return true
          }
        }
      }
      return false
    }

    // 递归菜单地址
    const recursionMenuPath = (data, needKey) => {
      let path
      for (const item of data) {
        if (item.key == needKey) {
          stagingKey = item.children[0].key
          return (path = item.children[0].path)
        }
        if (item.children) path = recursionMenuPath(item.children, needKey)
      }
      return path
    }

    const select = node => {
      clear(node.key)
      // 选择跳转菜单
      state.selectedKeys = [node.key]
      //获取根节点无子节点key
      const keys = state.navData.filter(item => !item.children).map(item => item.key)
      //点击最外层关闭展开的菜单
      if (keys.includes(node.key)) {
        state.openKeys = []
      }
      console.log('node', node, state.selectedKeys, keys)
      // let path = node.item['data-path'] // 2.2.8
      let path = '/' + node['keyPath'].join('/')
      // console.log('router', node,'/' + node['keyPath'].join('/'))
      if (node['keyPath'].length > 1) {
        path =
          '/' +
          node['keyPath']
            .map(e => e)
            .reverse()
            .join('/')
        // path = '/' + node['keyPath'].join('/')
        // console.log('router2', node,path)
      }
      // const path =
      //   item.domEvent.target.tagName === 'SPAN'
      //     ? item.domEvent.target.parentNode.dataset['path']
      //     : item.domEvent.target.dataset['path']
      path !== $route.path && $router.push({ path })
    }
    const renderMenu = item => {
      const slots = {
        title: () => (
          <span>
            {item.icon && <i class={['iconfont svg', iconPathObj[item.icon]].join(' ')}></i>}
            {item.title}
          </span>
        ),
        expandIcon: () => <i class="iconfont icon-cebianlanjiantou open-arrow"></i>,
        default: () => item.children.map(it => renderMenu(it))
      }
      if (item.children) {
        return <SubMenu v-slots={slots} key={item.key}></SubMenu>
      }
      return (
        <Item key={item.key} data-path={item.path}>
          <span>
            {item.icon && <i class={['iconfont svg', iconPathObj[item.icon]].join(' ')}></i>}
            {item.title}
          </span>
        </Item>
      )
    }

    watch(
      () => $route.fullPath,
      () => {
        console.log('$route', $route.matched, state.selectedKeys)
        if ($route.matched.length === 0) return []
        if ($route.matched[$route.matched.length - 1].name !== state.selectedKeys[0]) {
          // 点击历史返回的情况

          console.log('$route.matched', $route.matched, state.selectedKeys)
          if ($route.matched.length === 2) {
            let selectedKey
            if ($route.matched[1].name == 'sealManageadds') {
              selectedKey = $route.matched[1].name.replace(/adds/, '')
            } else if ($route.matched[1].name.includes('add')) {
              selectedKey = $route.matched[1].name.replace(/add/, '')
              // selectedKey = $route.matched[1].name.replace(/adds/, '')
            } else if ($route.matched[1].name.includes('fence')) {
              selectedKey = $route.matched[1].name.replace(/fence/, '')
            } else if ($route.matched[1].name.includes('edit')) {
              selectedKey = $route.matched[1].name.replace(/edit/, '')
            } else if ($route.matched[1].name.includes('yzgDetail')) {
              //设备信息
              selectedKey = $route.matched[1].name.replace(/yzgDetail/, '')
            }

            //TODO：印章管理-批量设置
            else if ($route.matched[1].name.includes('setSealManage')) {
              selectedKey = $route.matched[1].name.replace(/setSealManage/, '')
            } else if ($route.matched[1].name.includes('setPrivilegedPerson')) {
              selectedKey = $route.matched[1].name.replace(/setPrivilegedPerson/, '')
            } else if ($route.matched[1].name.includes('setViewer')) {
              selectedKey = $route.matched[1].name.replace(/setViewer/, '')
            } else if ($route.matched[1].name.includes('detail')) {
              selectedKey = $route.matched[1].name.replace(/detail/, '')
            } else {
              selectedKey = $route.matched[1].name
            }
            state.selectedKeys = [selectedKey]
          } else if ($route.matched.length === 3) {
            state.openKeys = [$route.matched[1].name]
            let selectedKeys
            if ($route.matched[2].name.includes('elecdetail')) {
              selectedKeys = $route.matched[2].name.replace(/elecdetail/, '')
            }
            // else if ($route.matched[2].name.includes('elecinformdetail')) {
            //   selectedKeys = $route.matched[2].name.replace(/elecinformdetail/, '')
            // }
            // else if ($route.matched[2].name.includes('entityinformdetail')) {
            //   selectedKeys = $route.matched[2].name.replace(/entityinformdetail/, '')
            // }
            else if ($route.matched[2].name.includes('approveddetail')) {
              //已审批详情
              selectedKeys = $route.matched[2].name.replace(/approveddetail/, '')
            } else if ($route.matched[2].name.includes('pendingdetail')) {
              //待审批详情
              selectedKeys = $route.matched[2].name.replace(/pendingdetail/, '')
            } else if ($route.matched[2].name.includes('detail')) {
              selectedKeys = $route.matched[2].name.replace(/detail/, '')
            } else if ($route.matched[2].name.includes('addElec')) {
              selectedKeys = $route.matched[2].name.replace(/addElec/, '')
            } else if ($route.matched[2].name.includes('add')) {
              selectedKeys = $route.matched[2].name.replace(/add/, '')
            } else if ($route.matched[2].name.includes('fence')) {
              selectedKeys = $route.matched[2].name.replace(/fence/, '')
            } else if ($route.matched[2].name.includes('edit')) {
              selectedKeys = $route.matched[2].name.replace(/edit/, '')
            } else if ($route.matched[2].name.includes('operationLogDetail')) {
              //操作日志详情
              selectedKeys = $route.matched[2].name.replace(/Detail/, '')
            } else {
              selectedKeys = $route.matched[2].name
            }
            state.selectedKeys = [selectedKeys]
            // console.log([selectedKeys])
          } else if ($route.matched.length === 4) {
            state.openKeys = [$route.matched[1].name, $route.matched[2].name]
            let selectedKeys
            // 在这里处理第四个匹配项的情况
            if ($route.matched[3].name === 'business') {
              // 如果第四个匹配项的名称为 'business'，则设置对应的 selectedKeys
              selectedKeys = $route.matched[3].name
            } else if ($route.matched[3].name === 'archive') {
              selectedKeys = $route.matched[3].name
            } else {
              // 如果有其他情况，可以根据需要进行处理
            }
            state.selectedKeys = [selectedKeys]
          }
        }
      },
      {
        immediate: true
      }
    )

    watch(
      () => state.enterpriseId,
      () => {
        state.openKeys = []
      }
    )
    onUpdated(() => {
      state.enterpriseId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).enterpriseId
    })

    return () => (
      <div class="nav-menu">
        <Menu
          class="scroll-bar-hide"
          openKeys={state.openKeys}
          selectedKeys={state.selectedKeys}
          onOpenChange={openChange}
          onClick={select}
          mode="inline"
        >
          {state.navData.map(item => renderMenu(item))}
        </Menu>
      </div>
    )
  }
}
</script>

<style lang="scss">
.nav-menu {
  // position: fixed;
  width: 208px;
  height: 100%;
  // border-right: 1px solid #f0f0f0;
  z-index: 100;
  font-size: 16px;

  .ant-menu-root {
    // position: absolute;
    // top: 48px;
    // bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 0 40px;
    border: none;
    width: 208px;
    background: #001529;

    // box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
    .ant-menu-sub {
      background: #001529;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 208px;
      //padding: 0 17px 0 16px !important;
      margin: 0;
      margin-bottom: 17px;
      line-height: 40px;
      height: 40px;
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.65);
      background: #001529;

      .ant-menu-submenu-arrow {
        color: #bec4cb;
      }
    }

    .ant-menu-submenu {
      background: #001529;
    }

    .ant-menu-submenu-title {
      display: flex;
      justify-content: space-between;
    }

    .ant-menu-item:hover {
      background: #293a4b;
      color: #ffffff;
    }

    .ant-menu-item-selected,      //左侧菜单颜色
    .ant-menu-item-selected:hover {
      background: #0A7BFF;
      color: #fff;
    }

    .open-arrow {
      font-size: 12px;
      display: inline-block;
      transition: 0.3s;
    }

    .ant-menu-submenu-open {
      > .ant-menu-submenu-title .open-arrow {
        transform: rotateZ(-180deg);
      }
    }

    .svg {
      font-size: 14px;
      display: inline-block;
      margin-right: 13px;
    }

    .ant-menu-item-selected::after {
      border-right: none;
    }
  }
}
</style>
