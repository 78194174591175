import { notification, message } from 'ant-design-vue'
import * as dd from 'dingtalk-jsapi' // 钉钉jsapi
import moment from 'moment'
import { getFiles } from '@/apis/seal'

var chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
var chnUnitChar = ['', '十', '百', '千']
var chnUnitSection = ['', '万', '亿', '万亿', '亿亿']

export const cmsNotice = (type, contant, duration = 4000) => {
  notification[type]({
    message: '提示',
    description: contant,
    duration: 4
  })
}

export const cmsMessage = (type, contant, duration = 4000) => {
  message[type]({
    message: '提示',
    content: contant,
    duration: 4
  })
}

export const throttle = (fn, delay) => {
  let start = Date.now()
  let end
  const self = this

  return function(...args) {
    if (!end) {
      fn.apply(self, args)
      end = start = Date.now()
      return
    }
    end = Date.now()
    if (end - delay >= start) {
      start = Date.now()
      fn.apply(self, args)
    }
  }
}

export const flatLevelArrayProperty = (arr, property = 'value', childName = 'children') => {
  // 多层数字扁平为一维，拿出关键id
  const result = []
  function deepIterationArr(arr) {
    result.push(arr[property])
    if (arr[childName] && arr[childName].length) {
      arr[childName].forEach(item => deepIterationArr(item))
    }
  }
  Array.isArray(arr) ? arr.forEach(item => deepIterationArr(item)) : deepIterationArr(arr)
  return result
}

export const iterationArrayToMap = (array, map, val = true) => {
  // 用于antd 组件change或check事件
  array.forEach(item => {
    map[item] = val
  })
}

export const setTablePageFooterStyle = tableRef => {
  setTimeout(() => {
    const totalEle = tableRef.value?.$el.getElementsByClassName('ant-pagination-total-text')[0]
    // console.log('totalEle的值', totalEle)
    if (totalEle) {
      const totalWidth = totalEle.offsetWidth
      const sizeEle = tableRef.value.$el.getElementsByClassName('ant-pagination-options-size-changer')[0]
      const sizeWidth = sizeEle.offsetWidth
      totalEle.style.marginRight = sizeWidth + 32 + 'px'
      sizeEle.style.left = totalWidth + 16 + 'px'
    }
  })
}

/*
 ** gOnlyKey 产生任意长度随机字母数字组合  动态生成组件唯一标识
 ** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
 */

export const gOnlyKey = (min = 2, max = 5) => {
  let str = '',
    range = min,
    arr = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]
  // 随机产生
  range = Math.round(Math.random() * (max - min)) + min
  for (let i = 0; i < range; i++) {
    let pos = Math.round(Math.random() * (arr.length - 1))
    str += arr[pos]
  }
  return str
}

export const ToCDB = str => {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    if (code >= 65281 && code <= 65374) {
      result += String.fromCharCode(str.charCodeAt(i) - 65248)
    } else if (code === 12288) {
      result += String.fromCharCode(str.charCodeAt(i) - 12288 + 32)
    } else {
      result += str.charAt(i)
    }
  }
  return result
}

/*
 *  系统枚举：
 *    key-枚举类型
 *    code-枚举值
 *    name-枚举名称
 *
 */
const systemEnums = {
  // 朝代更替
  dynasty: [
    {
      name: '唐朝',
      code: 'tang'
    },
    {
      name: '宋朝',
      code: 'song'
    }
  ]
}

/** 获取项目下所有的枚举
 *  维护地址：https://b3iqpm8znv.feishu.cn/docs/doccnlddQuCQ2nQF3hPnFeWAwwe?from=from_copylink
 *  参数说明:
 *  type：类型 (必传)
 *  code：转义字符 (非必传)
 *
 *  说明: 只传 type 返回数组 list，两者都传返回转义字符对应的子项
 *
 *  枚举对照维护文档：https://b3iqpm8znv.feishu.cn/docs/doccnlddQuCQ2nQF3hPnFeWAwwe?from=from_copylink
 */
export const getEnums = (type, code = undefined) => {
  const enums = JSON.parse(localStorage.getItem('enums'))
  let list = [],
    arr = [],
    res = []
  // console.log('enums', type, code)
  if (type) {
    // console.log('enums', enums)
    if (!enums) {
      return
    }
    enums.forEach(item => {
      if (type === item.kind) {
        list.push(item)
      }
    })
    Object.keys(systemEnums).forEach(key => {
      if (type === key) {
        list.push(...systemEnums[key])
      }
    })
    // 处理参数
    list.forEach(item => {
      arr.push({
        ...item,
        desc: item.desc || item.name,
        code: item.code
      })
    })
    // 返回值
    code !== undefined && arr.length ? (res = arr.find(item => item.code === code)) : (res = arr)
  }
  return res
}

// 是否企业微信
export function checkQywx() {
  return /wxwork/i.test(navigator.userAgent)
}

export const QYWX = 'QYWX'
export const DINGTALK = 'DINGTALK'

// 是否手机端
export function checkMobile() {
  return window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

// 判断平台：钉钉。企业微信。浏览器
export function environmentJudge() {
  const isMobile = window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  const isQywx = /wxwork/i.test(navigator.userAgent)
  if (isQywx) {
    // 企业微信
    return QYWX
  } else if (dd.env.platform != 'notInDingTalk') {
    // 钉钉
    return DINGTALK
  }
  return 'OTHERS'
}

// 数字转中文
export function numberToChanie(num) {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  let getWan = temp => {
    let strArr = temp
      .toString()
      .split('')
      .reverse()
    let newNum = ''
    let newArr = []
    strArr.forEach((item, index) => {
      newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
    })
    let numArr = []
    newArr.forEach((m, n) => {
      if (m !== '零') numArr.push(n)
    })
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m
          }
        } else {
          newNum += m
        }
      })
    } else {
      newNum = newArr[0]
    }

    return newNum
  }
  let overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

// 处理详情数据函数
/**
 * formData 详情每个控件填的数据
 * items 详情每个控件
 * source 来源 抄送详情 0 、 申请详情 1 、审批详情 2 、 记录详情 3
 * hasArchaic  是不是旧数据 不太确定了 后续需要的话可以问一下后端 此值是后端直接给的
 * fileElseList 上传的文件数据
 * hasCover 是否是补盖
 * qrcodeDiffList二维码校验记录
 * fileCheckList文件校验记录
 * status 1、特权用户用印 2、普通用印 3、电子签章
 */
export async function dealDetailData(
  formData,
  items,
  source,
  hasArchaic,
  fileElseList,
  hasCover,
  codeData,
  qrcodeData,
  fileCheckList,
  processMold,
  status
) {
  const finallyList = []
  let applyFileName = ''
  const nameEnum = {
    SEAL_INFO: 'SealInfo',
    FILE_NAME: 'TextInput',
    REMOTE: 'GreyBottomCard',
    TAKE_OUT: 'GreyBottomCard',
    FILE: 'FileUpload',
    TASK_CODE: 'TaskCode'
    // NAME: 'TextInput',
    // MOBILE: 'Phone'
  }
  console.log('formData有什么', formData)
  if (hasCover && source === 2) {
    console.log('是补盖文件吗', items)
    const sealInfoList = items.filter(el => el.type === 'SEAL_INFO') || null
    let finallySealInfoList = []
    if (sealInfoList) {
      for (const element of sealInfoList) {
        let obj = {
          id: element.id,
          titles: element.title,
          controlName: 'SealInfo',
          value: '',
          type: element.type
        }
        obj.value = await dealControlDataValue(
          obj.controlName,
          element,
          formData[element.id],
          source,
          hasArchaic,
          fileElseList,
          hasCover,
          codeData,
          qrcodeData
        )
        console.log([obj], '989899/*/*')
        finallySealInfoList.push(obj)
      }
      console.log('wozaichouchouc', [finallySealInfoList])
      return [finallySealInfoList]
    }
    return []
  }
  for (const item of items) {
    console.log('item里面有什么', item)
    // 创建详情对象
    let obj = {
      id: item.id,
      titles: item.title,
      controlName: '',
      value: '',
      type: item.type
    }
    if (item.controlName !== 'FileUpload') {
      if (['MOBILE', 'NAME'].includes(item.type)) {
        obj.controlName = 'CustomFormItem'
      } else {
        item.type ? (obj.controlName = nameEnum[item.type]) : (obj.controlName = 'CustomFormItem')
      }
    } else {
      obj.controlName = 'FileUpload'
      obj = { ...obj, ...qrcodeData }
    }

    // 外带为否时直接不插入数据
    if (obj.titles === '是否外带') {
      if (formData[item.id][0].takeout !== 2) continue
    }
    console.log('status', status)
    if (
      status == 1 &&
      (item.title == '盖印方式' || item.title == '预计用印日期' || item.title == '申请事由' || item.title == '上传文件')
    ) {
      console.log('item.title', item.title)
      continue
    }
    console.log('obj.controlName',obj.controlName)
    obj.value =
      formData[item.id] || formData[item.id] === 0
        ? await dealControlDataValue(
            obj.controlName,
            item,
            formData[item.id],
            source,
            hasArchaic,
            fileElseList,
            hasCover,
            codeData,
            qrcodeData,
            status
          )
        : undefined
    // 根据类型插入数据
    if (obj.controlName !== 'SealInfo' && obj.controlName !== 'TextInput') {
      if (finallyList.length <= 0) {
        finallyList.push([obj])
        continue
      }
      if (
        finallyList[finallyList.length - 1][0].controlName !== 'SealInfo' &&
        finallyList[finallyList.length - 1][0].controlName !== 'TextInput'
      ) {
        finallyList[finallyList.length - 1].push(obj)
      } else {
        finallyList.push([obj])
      }
    } else {
      // 为印章详情控件且为用印记录页需要将印章分开
      if (obj.controlName === 'SealInfo' && source === 3) {
        obj.value.forEach((item, index) => {
          finallyList.push([{ ...obj, id: obj.id + index, value: [item], qrcodeDiffList: qrcodeData?.qrcodeDiffList }])
        })
      } else {
        finallyList.push([obj])
      }
    }
    if (item.type === 'FILE_NAME') {
      const applyTit = await dealControlDataValue(
        obj.controlName,
        item,
        formData[item.id],
        source,
        hasArchaic,
        fileElseList,
        hasCover,
        codeData
      )
      applyFileName = applyTit.name
    }
  }
  console.log(finallyList, '我看看它')
  fileCheckList &&
    finallyList.push([
      {
        controlName: 'FileCheck',
        type: 'FILE_CHECK',
        id: 'dggre',
        fileName: applyFileName,
        value: fileCheckList
        // value: [
        //   {
        //     name: '市场部文件',
        //     suffix: 'pdf',
        //     fileId: 1051271,
        //     operationList: [
        //       { operationName: '张某', operationTime: '2024-01-23 16:00:00' },
        //       { operationName: '张某2', operationTime: '2024-01-24 16:00:00' },
        //       { operationName: '张某2', operationTime: '2024-01-24 16:00:00' },
        //       { operationName: '张某2', operationTime: '2024-01-24 16:00:00' },
        //       { operationName: '张某2', operationTime: '2024-01-24 16:00:00' }
        //     ]
        //   },
        //   {
        //     name: '软件部文件',
        //     suffix: 'word',
        //     fileId: 1051671,
        //     operationList: [
        //       { operationName: '张某', operationTime: '2024-01-23 16:00:00' },
        //       { operationName: '张某2', operationTime: '2024-01-24 16:00:00' }
        //     ]
        //   }
        // ]
      }
    ])
  return finallyList
}

// 处理控件数据值
export async function dealControlDataValue(
  controlName,
  control,
  data,
  source,
  hasArchaic,
  fileElseList,
  hasCover,
  codeData,
  qrcodeData
) {
  const dealFunObj = {
    // 印章信息
    SealInfo: () => {
      return data.map(item => {
        console.log('item', item)
        const obj = {
          status: item.status,
          sealId: item.sealId,
          sealName: item.sealName,
          basicInfo: dealSealHeadKey(source, item, hasCover),
          fillCover: dealFillCoverData(source, item.sealCoverFormDataVO, hasCover, codeData, item.sealId),
          type: source,
          urgInfo: urgListFormate(
            item.sealId,
            item.sealStaffRelVOList ?? item.sealCoverFormDataVO.sealCoverStaffRelVOList
          )
        }
        if (source === 3) {
          obj['imageData'] = dealImageData(item)
        }
        if (hasArchaic) {
          obj['commonFlowList'] = item.commonFlowList
        }
        return obj
      })
    },
    // 流程主题
    TextInput: () => {
      console.log('data', data)
      return {
        name: data[0].fileName,
        code: data[0].code,
        codeData: codeData
      }
    },
    // 盖印方式、外带信息
    GreyBottomCard: () => {
      let dataList
      if (control.type === 'REMOTE') {
        const sealTypeList = ['常规盖印', '远程盖印', '连续盖印']
        dataList = [{ titles: '盖印方式', value: sealTypeList[data[0].remote - 1] }]
        if (data[0].remote > 1) {
          dataList.push(
            data[0].remote === 2
              ? { titles: '远程确认人', value: data[0].remoteStaff?.staffName || '' }
              : { titles: '连续盖印', value: '已开启' }
          )
        }
      } else {
        dataList = [
          { titles: '外带时间', value: data[0].startTime + '-' + data[0].finishTime },
          { titles: '外带地点', value: data[0].addressList }
        ]
      }
      return dataList
    },
    // 上传文件
    FileUpload: async () => {
      if (control.type) {
        if (control.type === 'FILE_ELSE') {
          return data.map(item => {
            const index = fileElseList.findIndex(el => el.fileId === item.fileId)
            return { ...fileElseList[index] }
          })
        }
        return data
      }
      if (data.length > 0) {
        const res = await getFiles({
          ids: data.map(item => item.fileId).join(','),
          isPagination: false
        })
        if (res.code === 0) {
          return res.data.map(item => {
            return {
              fileId: item.fileId,
              fileName: item.name,
              fullFile: item.fullUrl,
              suffix: item.suffix
            }
          })
        }
      }
      return []
    },
    // 可粘连信息
    CustomFormItem: () => {
      const directOutPutData = ['TextInput', 'TextareaInput', 'SelectInput', 'DateTime', 'Phone']
      if (directOutPutData.includes(control.controlName)) return data
      // 多选
      if (control.controlName === 'MultipleSelect') return data.join('、')
      // 日期区间
      if (control.controlName === 'DateTimeRange') {
        let duration
        if (data.length === 1) {
          return data[0] + '-' + '-' + ',' + control.props.durationTitle + ':' + '-'
        }
        if (control.props.format === 'yyyy-MM-DD') {
          const temporaryTime = moment(data[1]).diff(moment(data[0]), 'days')
          duration = temporaryTime + 1
        } else {
          const temporaryTime = moment(data[1]).diff(moment(data[0]), 'minutes')
          duration = (Number((temporaryTime / 60).toFixed(1)) * 100) / 100
        }
        // const duration = moment(data[1]).diff(moment(data[0]), control.props.format === 'yyyy-MM-DD' ? 'days' : 'hours')
        const unit = control.props.format === 'yyyy-MM-DD' ? '天' : '小时'
        return data.join('-') + '，' + control.props.durationTitle + '：' + duration + unit
      }
      if (control.controlName === 'Address') return data[0]?.address
      if (control.controlName === 'AmountInput') {
        let currencyType = control.props.currencyType === '人民币' ? '元' : control.props.currencyType
        return data + currencyType
      }
      if (control.controlName === 'NumberInput') return data + control.props.unit
    },
    // taskCode
    TaskCode: () => {
      console.log('data', data[0].taskCode)
      return {
        taskCode:data[0].taskCode
      }
    }
  }
  console.log('controlName',controlName)
  return await dealFunObj[controlName]()
}
// 处理印章头部字段
function dealSealHeadKey(source, itemData, hasCover) {
  // 所有需要的字段
  let allKey = {
    申请次数: itemData.applicationNumber,
    申请补盖次数: itemData.sealCoverFormDataVO ? itemData.sealCoverFormDataVO.reApplicationNumber : '',
    盖印人: getSealStaffRel(source, hasCover, itemData),
    实际盖印次数: itemData.practicalNumber
  }
  const keyDataObj = {
    0: ['申请次数', '盖印人'],
    1: ['申请次数', '盖印人'],
    3: ['盖印人', '实际盖印次数']
  }
  if (source === 2 && itemData.sealCoverFormDataVO) {
    keyDataObj['2'] = ['申请补盖次数', '盖印人']
  } else {
    keyDataObj['2'] = keyDataObj['1']
  }
  let obj = {}
  keyDataObj[source].forEach(item => {
    obj[item] = allKey[item]
  })
  return obj
}

// 获取头部盖印人数据
function getSealStaffRel(source, hasCover, itemData) {
  if (source === 2 && hasCover) {
    return itemData.sealCoverFormDataVO
      ? itemData.sealCoverFormDataVO.sealCoverStaffRelVOList.map(item => item.staffName)
      : []
  }
  if (source === 3) {
    return itemData.actualStaffVOList ? itemData.actualStaffVOList.map(item => item.staffName) : []
  }
  return itemData.sealStaffRelVOList ? itemData.sealStaffRelVOList.map(item => item.staffName) : []
}
// 处理印章补盖信息
function dealFillCoverData(source, coverData, hasCover, codeData, sealId) {
  console.log(coverData, '69696', hasCover)
  // coverData = {...coverData , 'codeData': codeData.documentCoverUpCodeVOList}
  if (source === 3 && coverData) return dealDataResult(source, coverData, codeData, sealId)
  if (hasCover && coverData) return dealDataResult(source, coverData, codeData, sealId)
  return null
}
// 补盖数据最终结果
function dealDataResult(source, coverData, codeData, sealId) {
  // console.log('coverData',coverData);
  const obj = {
    source: source,
    coverInfo: null,
    causeAndFile: [
      {
        controlName: 'CustomFormItem',
        titles: '补盖原因',
        value: '我就是原因'
      },
      {
        controlName: 'FileUpload',
        titles: '补盖附件',
        value: coverData.fileFormDataVOList
      }
    ],
    coverFlowList: coverData.coverFlowList,
    urgInfo: urgListFormate(sealId, coverData.sealCoverStaffRelVOList),
    codeData: codeData?.documentCoverUpCodeVOList,
    sealId: sealId
  }
  if (source !== 2) {
    if (source === 3) {
      obj.coverInfo = {
        补盖申请人: coverData.affixedStaffName,
        补盖盖印人:
          (coverData.actualCoverStaffVOList &&
            coverData.actualCoverStaffVOList.map(item => item.staffName).join('、')) ||
          null,
        实际补盖次数: coverData.rePracticalNumber
      }
    } else {
      source === 1
        ? (obj.coverInfo = {
            申请补盖次数: coverData.reApplicationNumber,
            补盖盖印人:
              (coverData.sealCoverStaffRelVOList &&
                coverData.sealCoverStaffRelVOList.map(item => item.staffName).join('、')) ||
              null,
            补盖申请人: coverData.affixedStaffName
          })
        : (obj.coverInfo = {
            补盖申请人: coverData.affixedStaffName,
            补盖盖印人:
              (coverData.sealCoverStaffRelVOList &&
                coverData.sealCoverStaffRelVOList.map(item => item.staffName).join('、')) ||
              null,
            实际补盖次数: coverData.rePracticalNumber
          })
    }
  }
  const reasonList = ['盖错位置', '盖印不清晰', '盖印中断', '申请数量不够']
  obj.causeAndFile[0].value = coverData.reReason - 1 >= 4 ? coverData.reText : reasonList[coverData.reReason - 1]
  return obj
}
// 处理影像信息
function dealImageData(data) {
  let obj = {
    faces: '环境影像',
    documentFileRelVO: '用印影像',
    documentFileVideoList: '用印视频',
    documentRemoteVideoList: '远程确认视频',
    documentRemoteVice: '远程确认影像',
    ocrFileVoList: 'OCR对比记录'
  }
  // 注 此处list顺序修改后，请修改 src\components\detailForm\components\sealList\components\imageInfoItem.vue 对应类型判断
  const list = [
    'faces',
    'documentFileRelVO',
    'documentFileVideoList',
    'documentRemoteVideoList',
    'documentRemoteVice',
    'ocrFileVoList'
  ]
  const result = {}
  list.forEach((item, index) => {
    if (data[item]) {
      if (data[item].length > 0) {
        result[obj[item]] = data[item].map(el => {
          console.log('结果对象', el)
          let obj = {
            fullFile: el.fullFile,
            type: index
          }
          if (index === 0) {
            return {
              ...obj,
              address: el.address,
              imageType: el.imageType,
              staffName: el.staffName || '',
              time: el.createTime ? el.createTime.substr(0, el.createTime.length - 3) : ''
            }
          }
          if (index === 1) {
            return {
              ...obj,
              address: el.address,
              imageType: el.imageType,
              time: el.sealTime ? el.sealTime.substr(0, el.sealTime.length - 3) : '',
              staffName: el.staffName || '',
              photoType: el.photoType,
              pictureType: el.pictureType,
              deviceType: el.deviceType ?? null
            }
          }
          if (index === 2) {
            return { ...el }
          }
          if (index === 3) {
            return { ...obj, staffName: el.staffName || '', fileType: 2 }
          }
          if (index === 4) {
            return { ...obj, staffName: el.staffName || '' }
          }

          if (index === 5) {
            return {
              ...el,
              type: index,
              time: el.createTime ? el.createTime.substr(0, el.createTime.length - 3) : ''
            }
          }
        })
      }
    }
  })
  if (Object.keys(result).length <= 0) {
    result['用印影像'] = []
  }
  // result['用印影像'] = result['用印影像'].filter(item => item.deviceType !== 2)

  console.log(result, '我哦哦')
  return result
}

// 催办数据格式 formate
function urgListFormate(sealId, sealStaffRelVOList) {
  const { urge, urgeRecord } = sealStaffRelVOList[0]
  var staffIds = sealStaffRelVOList.map(item => item.staffId)
  const obj = { sealId, urge, urgeRecord, staffIds }
  return obj
}
