// 路由守卫及权限管理
import router from '@/router/config-router'
import store from '@/store'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { SET_ROUTER } from '@/store/router/mutations-type'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import { getUserInfo } from './apis/user'
import { approveDetail } from './apis/approval'
import { checkQywx } from './utils/utils'
import { notification } from 'ant-design-vue'
import { environmentJudge } from '@/utils/utils.js'
import { getExplore } from 'szyutils'

// // 白名单
const whiteList = ['/login', '/binding', '/error'] // 无需登陆的页面
let flag = true
// // 守卫
router.beforeEach(async (to, from, next) => {
  // console.log('下个路由',to.path)
  // 判断环境
  const exploreList = ['Edge', 'Firefox', 'Chrome', 'Safari', 'Opera']
  const explore = getExplore()
  const exploreName = explore.split(':')[0]
  // const exploreName = 'uc'
  const environment = environmentJudge()
  if (environment == 'OTHERS' && exploreList.indexOf(exploreName) == -1 && flag) {
    flag = false
    next(`/error`)
    return
  }
  if (to.path == '/error' && environment == 'OTHERS' && exploreList.indexOf(exploreName) != -1) {
    next(`/login`)
    return
  }

  //印章管理
  if (
    to.path !== '/sealManage/setSealManage' &&
    to.path !== '/sealManage/setViewer' &&
    to.path !== '/sealManage/setPrivilegedPerson' &&
    to.path !== '/sealManage'
  ) {
    setTimeout(() => {
      localStorage.removeItem('checkedRows')
      localStorage.removeItem('paginationParams')
      store.state.sealManage.checkedRows = []
      let paginationParams = {
        pageSize: 10,
        total: 0,
        current: 1,
        index: 0,
        'show-size-changer': true,
        'show-quick-jumper': true
      }
      store.state.sealManage.paginationParams = paginationParams
    }, 500)
  }

  const isQywx = checkQywx()
  const enterpriseId = to.query.enterpriseId
  if (enterpriseId) {
    localStorage.setItem('enterpriseId', enterpriseId)
  }
  // console.log('to', to)
  // console.log('from', from)
  // 判断本地缓存
  let userInfo = localStorage.getItem('yda-admin-userInfo') && JSON.parse(localStorage.getItem('yda-admin-userInfo'))
  const oldSessionId = localStorage.getItem('yda-qywx-sessionId')
  let sessionId = to.query.sessionId || localStorage.getItem('yda-qywx-sessionId')
  if (sessionId && oldSessionId != sessionId) {
    localStorage.removeItem('yda-qywx-sessionId')
    localStorage.removeItem('yda-admin-userInfo')
    localStorage.removeItem('checkedRows')
    localStorage.removeItem('paginationParams')
    userInfo = null
  }
  sessionId && localStorage.setItem('yda-qywx-sessionId', sessionId)
  console.log('permission sessionId', sessionId)
  // localStorage.clear()

  // if (isQywx && !sessionId) {
  //   window.location.href = process.env.VUE_APP_QYWX_URL
  //   return
  // }
  if (!userInfo && sessionId) {
    console.log('permission !userInfo && sessionId', sessionId)
    // 企业微信 进入这里
    // 保存 sesionID 获取用户信息
    // localStorage.setItem('yda-qywx-sessionId', sessionId)
    const { data: res } = await getUserInfo()
    console.log('permission getUserInfo', res)
    if (!res || !res?.userId) {
      window.location.href = process.env.VUE_APP_QYWX_URL + `?isFailPC=1&enterpriseId=${enterpriseId}`
      // next({ path: '/login', query: { sessionId } })
      return
    }
    userInfo = {
      ...res,
      userName: res.userName,
      userId: res.result.staffId,
      id: res.userId,
      sexName: res.sexName,
      avatar: res.avatar,
      allEnterprise: res.result.allEnterprise,
      mobile: res.mobile,
      nickName: res.nickname,
      enterpriseBindLibawall: res.result.enterpriseBindLibawall,
      isNotInitPassword: res.isNotInitPassword,
      enterpriseId: res.result.enterpriseId,
      enterpriseName: res.result.enterpriseName
    }
    localStorage.setItem('enterpriseId', res.result.enterpriseId)
    store.commit(`user/${SET_USER_INFO}`, userInfo)
    localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
    saveUserData(userInfo.userId, res.result.enterpriseId)

    if (!res.mobile) {
      console.log('导航守卫 !res.mobile userInfo', userInfo)
      console.log('导航守卫 !res.mobile sessionId', sessionId)
      next('/binding')
      return
    }
  }
  //获取枚举
  await store.dispatch('enums/getList')
  // 第三方PC跳转指定页面
  const businessType = to.query.businessType
  console.log('businessType', businessType)
  if (businessType == 1 || businessType == 13 || businessType == 15) {
    // 待审批
    const documentId = to.query.documentId
    const flag = to.query.flag
    const sealId = to.query.sealId
    const sealType = to.query.sealType
    const taskId = to.query.taskId
    const notice = to.query.notice || false
    console.log('sealType', sealType)
    const res = await approveDetail(to.query.documentId, {
      taskId: to.query.taskId
    })
    //判断当前审批状态
    if (res.code == 0) {
      next(
        `/approval/approved/${
          res.data.hasPending ? 'pendingdetail' : 'approveddetail'
        }?documentId=${documentId}&flag=${flag}&sealId=${sealId}&sealType=${sealType}&taskId=${taskId}&notice=${notice}&hasCover=${businessType ==
          13 || businessType == 15}`
      )
    } else {
      next(
        `/approval/notApproval/pendingdetail?documentId=${documentId}&flag=${flag}&sealId=${sealId}&sealType=${sealType}&taskId=${taskId}&hasCover=${businessType ==
          13 || businessType == 15}`
      )
    }
  } else if (businessType == 7 || businessType == 8 || businessType == 14) {
    // 用印记录
    const id = to.query.id
    const sealType = to.query.sealType
    const processInstanceId = to.query.processInstanceId
    console.log('sealType', sealType)
    if (sealType == 1) {
      next(`/seal/apply/detail?documentId=${id}&processInstanceId=${processInstanceId}`)
    } else if (sealType == 2) {
      // 电子签章
      next(`/seal/apply/elecDetail?documentId=${id}`)
    }
  } else if ([3, 4, 5, 6, 9, 19, 20, 21].includes(businessType)) {
    next('/warningCenter')
  } else if (businessType == 12) {
    // 抄送我的详情
    const dataId = to.query.dataId
    const sealType = to.query.sealType
    const sealId = to.query.sealId
    const processInstanceId = to.query.processInstanceId
    console.log('进来了抄送人', sealType, documentId)
    next(
      `/approval/informPeople/detail?dataId=${dataId}&sealId=${sealId}&processInstanceId=${processInstanceId}`
    )
  } else if (businessType == 16) {
    // ?businessType=16&activeKey=1&documentId=35640
    // 用印记录详情
    const status = to.query.status // 1特权 2常规
    let documentId = to.query.documentId
    const processInstanceId = to.query.processInstanceId
    if (status == 2) {
      next(`/seal/applyList/detail?documentId=${documentId}&processInstanceId=${processInstanceId}`)
    } else {
      next(`/seal/applyList?documentId=${documentId}&activeKey=1`)
    }
  }

  if (userInfo) {
    // console.log('permission userInfo 1', userInfo)
    if (!store.state.globalData.isFetchApplyProcess) {
      await new Promise(resolve => {
        store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`, resolve)
      })
    }
    if (store.state.router.routers.length === 0) {
      // 动态添加路由
      await new Promise(resolve => {
        store.dispatch(`router/${SET_ROUTER}`, resolve)
      })
      const redirect = decodeURIComponent(from.query.redirect || to.path)
      if (to.path === redirect) {
        if (businessType == 2) {
          notification['error']({
            message: '提示',
            description: '请在企业微信手机端或app内确认远程盖印信息',
            maxCount: 1
          })
        }
        next({ ...to, replace: true })
      } else {
        next({ path: redirect })
      }
    } else {
      if (isQywx && !userInfo.mobile && to.path !== '/binding') {
        next('/binding')
        return
      }
      if (to.path === '/login' && !to.query.switch) {
        next('/dashboard/analysis')
        return
      }
      next()
    }
  } else {
    console.log('permission userInfo 0', userInfo)
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

const saveUserData = (userId, enterpriseId) => {
  let userCp = JSON.parse(localStorage.getItem('yda-user-data')) || {}
  console.log('测试打印', userCp, userId, enterpriseId)
  userCp[userId] = enterpriseId
  localStorage.setItem('yda-user-data', JSON.stringify(userCp))
}
