import { SET_APPLY_PROCESS_LIST, SET_IS_Fetch_APPLY_PROCESS, SET_PAGE_RELOAD,SET_BACK_ROUTER } from './mutations-type'
import { getApplyProcessList } from '@/apis/businessManage'

export default {
  async [SET_APPLY_PROCESS_LIST]({ commit }, resolve) {
    const res = await getApplyProcessList()
    commit(`${SET_IS_Fetch_APPLY_PROCESS}`, true)
    commit(`${SET_APPLY_PROCESS_LIST}`, res.data)
    resolve && resolve()
  },
  SET_PAGE_RELOAD({ commit }, payload) {
    commit(`${SET_PAGE_RELOAD}`, payload)
  },
  SET_BACK_ROUTER({ commit }, value) {
    commit(`${SET_BACK_ROUTER}`, value)
  }
}
