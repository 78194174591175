/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-17 13:47:29
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-05-16 11:35:27
 * @FilePath: \yda_web_manage\src\apis\approval.js
 * @Description:
 *
 */
import request from '@/utils/request'

const api = {
  approval: '/pc/examine',
  apply: '/pc/document',
  notApproval: '/pc/examine/await'
}

// 获取已审批列表
export function getApprovedList(params) {
  return request({
    // url: `${api.approval}/show/v2.5.7`,
    url: '/pc/examine/approval/over/list',
    method: 'GET',
    params
  })
}

// 获取未审批列表
export function getNotApprovalList(params) {
  return request({
    // url: `${api.notApproval}/show/v2.5.7`,
    url: '/pc/examine/approval/pending/list',
    method: 'GET',
    params
  })
}

// 同意或拒绝审批
export function sendArchivedResult(data) {
  return request({
    // url: `/pc/examine/result/upload/v2.5.7`,
    url: `/pc/examine/result/upload/v2.5.9`,
    method: 'PUT',
    data
  })
}

// 补盖同意或拒绝审批
export function fillCoverResult(data) {
  return request({
    url: '/pc/examine/replenish',
    method: 'PUT',
    data
  })
}

// 获取审批详情
export function getApprovedDetail(params) {
  return request({
    // url: `/pc/details/document/examine/${params.documentId}/v2.5.7`,
    // url: `/pc/details/document/examine/${params.documentId}/v2.6.6`,
    url: '/pc/details/document/approved/details', //2.6.7
    method: 'GET',
    params //2.6.7
    // params: {
    //   viceId: params.documentId,
    //   flag: params.flag,
    //   sealId: params.sealId,
    //   flowType:params.flowType
    // }
  })
}

//获取电子签章审批详情
export function getElectronicApprovedDetail(params) {
  return request({
    url: `/pc/qys/apply/details/${params.documentId}`,
    method: 'GET'
  })
}

// 抄送我的列表数据
export function getCarbonCopy(params) {
  return request({
    url: '/pc/carbonCopy/list',
    method: 'GET',
    params
  })
}

// 抄送我的电子详情
export function carbonCopyEleDetails(documentId) {
  return request({
    url: `/pc/carbonCopy/${documentId}`,
    method: 'GET'
  })
}

// 抄送我的实体详情
export function carbonCopyDetails(params) {
  return request({
    url: `/pc/carbonCopy/${params.documentId}/${params.sealId}`,
    method: 'GET'
  })
}

//获取待审批/已审批详情
export function approveDetail(params) {
  return request({
    url: `/pc/examine/approval/all/details`,
    method: 'GET',
    params
  })
}

//3.0同意拒绝审批
export function approve(data) {
  return request({
    url: `/pc/examine/approval/result/upload`,
    method: 'PUT',
    data
  })
}

//获取抄送详情
export function copyDetail(documentId, params) {
  return request({
    url: `/pc/carbonCopy/details/${documentId}`,
    method: 'GET',
    params
  })
}

//获取所有文件类型
export function getAllType() {
  return request({
    url: `/pc/file/type/all`,
    method: 'GET'
  })
}

//获取归档管理的所有文件类型
export function getArchiveType() {
  return request({
    url: `/pc/document/archive/file/type/all`,
    method: 'GET'
  })
}

//获取归档管理列表
export function getArchiveList(params) {
  return request({
    url: `/pc/document/archive`,
    method: 'POST',
    data: params
  })
}

// 加签表单
export function subApproval (params) {
  return request({
    url: '/pc/examine/approval/addSign/submit',
    method: 'PUT',
    data: params
  })
}

