// 严凯 V2.2.0

import 'viewerjs/dist/viewer.css' //图片预览样式
import VueViewer from 'v-viewer' //图片预览
import { createApp } from 'vue'
import App from './App.vue' // 入口文件
import router from './router/config-router' // 路由定义
import store from './store' // 状态管理
import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'
// 新iconfont项目库
import '@/assets/iconfontnew/iconfont.js'
import '@/assets/iconfontnew/iconfont.css'
// xlog
import { xlog, logPost } from '@/utils/xlog'
// import { params } from './utils/xlogParams'
import directive from '@/utils/directive'
import VueCookies from 'vue-cookies'
import Ellipsis from '@/components/common/Ellipsis'
import {
  ConfigProvider,
  progress,
  Menu,
  Table,
  Button,
  Modal,
  Upload,
  Input,
  Space,
  Form,
  Switch,
  Radio,
  InputNumber,
  DatePicker,
  TimePicker,
  Checkbox,
  Tag,
  Image,
  Select,
  Row,
  Col,
  Card,
  Timeline,
  Tabs,
  Tree,
  Cascader,
  Dropdown,
  Breadcrumb,
  Spin,
  Avatar,
  Badge,
  Divider,
  Descriptions,
  List,
  Layout,
  Empty,
  Carousel,
  Popover,
  Tooltip,
  TreeSelect,
  Drawer
} from 'ant-design-vue'
import IconFont from '@/components/icons/iconFont.vue'

import './permission' // 全局路由守卫 权限控制
import './global.scss' // 全局样式
import { setupLoadMoreDirective } from '@/utils/loadMore.js' //表格滚动加载
// import 'ant-design-vue/dist/antd.less'

const app = createApp(App)
// xlog(app, params)
// console.log(process.env)

if (process.env.NODE_ENV == 'production') {
  // 异常上报
  xlog(app, { level: 'ERROR', version: '3.1.3' })
}

// logPost({ level: 'DEBUG', version: '2.2.1', info: {} })
app
  .use(store)
  .use(ConfigProvider)
  .use(Menu)
  .use(Table)
  .use(Button)
  .use(Modal)
  .use(Upload)
  .use(Space)
  .use(Form)
  .use(Switch)
  .use(Radio)
  .use(Input)
  .use(Select)
  .use(Image)
  .use(Tag)
  .use(Checkbox)
  .use(DatePicker)
  .use(TimePicker)
  .use(InputNumber)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Timeline)
  .use(Tabs)
  .use(Tree)
  .use(Cascader)
  .use(Dropdown)
  .use(Breadcrumb)
  .use(Spin)
  .use(Avatar)
  .use(Badge)
  .use(Divider)
  .use(Descriptions)
  .use(List)
  .use(Popover)
  .use(Empty)
  .use(Carousel)
  .use(Tooltip)
  .use(Drawer)
  .use(Layout)
  .use(VueViewer)
  .use(router)
  .use(TreeSelect)
  .use(VueCookies)
  .use(progress)

setupLoadMoreDirective(app)
app.component('IconFont', IconFont)
directive(app)
app.component('Ellipsis', Ellipsis)
app.config.globalProperties.$deepCopy = function(obj) {
  return JSON.parse(JSON.stringify(obj))
}
app.mount('#app')
