import { SET_ROUTER } from './mutations-type'
import asyncRouter from '@/router/async-router'
import router from '@/router/config-router'


const actions = {
  async [SET_ROUTER]({ commit }, resolve) {
    const { navList, routes } = await asyncRouter()
    const errorRouter={
      path:"/:pathMatch(.*)",
      redirect:routes.children[0].path || '/404'
    }
    router.addRoute(routes)
    router.addRoute(errorRouter)
    commit(`${SET_ROUTER}`, { routers: [routes,errorRouter], navMenus: navList })
    resolve && resolve()
  }
}

export default actions
