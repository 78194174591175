import { getEnums } from '@/apis/user'

const actions = {
  getList() {
    return new Promise((resolve, reject) => {
      let params = {
        pageIndex: 1,
        pageSize: 2000
      }
      getEnums(params)
        .then(res => {
          if (res.success) {
            localStorage.setItem('enums', JSON.stringify(res.data))
            resolve(res)
          } else {
            reject(res.message)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default actions
