import request from '@/utils/request'

const api = {
  user: '/pc/user'
}

//密码登录
export function passLogin(params) {
  return request({
    url: '/login',
    method: 'POST',
    params
  })
}

// 退出登录
export function logout(params) {
  return request({
    url: '/logout',
    method: 'GET',
    params
  })
}

// 选择企业
export function switchEnterprise(params) {
  return request({
    url: `${api.user}/switchEnterprise`,
    method: 'PUT',
    params
  })
}

// 获取所有权限列表
export function getAllPermissionList() {
  return request({
    url: '/pc/role/permission/all',
    method: 'GET'
  })
}

export function getUserInfo(params) {
  return request({
    url: '/pc/user/loginData',
    method: 'GET'
  })
}
// 获取路由权限列表
export function getRoutePermissionList() {
  return request({
    url: '/pc/permission/menu',
    method: 'GET'
  })
}

//获取枚举
export function getEnums(params) {
  return request({
    url: '/enums',
    method: 'GET',
    params
  })
}

// 修改账号密码
export function editAccountPassword(params) {
  return request({
    url: `${api.user}/modifyPassword`,
    method: 'POST',
    params
  })
}

// 修改账号
export function editAccount(params) {
  return request({
    url: `${api.user}/modifyPhone`,
    method: 'POST',
    params
  })
}

// 修改账号先验证当前手机号
export function checkCurrentPhone(params) {
  return request({
    url: `${api.user}/verifyCode`,
    method: 'POST',
    params
  })
}
// 获取修改账号 验证码
export function getAccountCaptcha(params) {
  return request({
    url: '/sendNote',
    method: 'GET',
    params
  })
}

// 忘记密码
export function setForgetPassword(data) {
  return request({
    url: '/pc/forgetPassword/rePassword',
    method: 'POST',
    data
  })
}

// 获取修改账号 头像
export function editAccountAvatar(params) {
  return request({
    url: `${api.user}/switchAvatarId`,
    method: 'PUT',
    params
  })
}

// 初始化用户密码
export function initUserPass(params) {
  return request({
    url: `${api.user}/initPassword`,
    method: 'POST',
    params
  })
}

// 修改头像
export function exchangeAvatar(params) {
  return request({
    url: '/file/upload/base64',
    method: 'POST',
    params
  })
}

// 获取自助申请入口判断
export function getDetailsApplyType(params) {
  return request({
    url: 'pc/details/apply/type',
    method: 'GET',
    params
  })
}

//授权
export function authorize(snsapi_privateinfo, redirectUri, state) {
  return request({
    url: `/wechatLogin/author/authorize?scope=${snsapi_privateinfo}&redirectUri=${redirectUri}&state=${state}`,
    method: 'GET'
  })
}

//登录
export function loginApi(params) {
  return request({
    url: `/login`,
    method: 'POST',
    params
  })
}

//企业微信绑定
export function boundPhone(data) {
  return request({
    url: `pc/setting/mergeMobile`,
    method: 'POST',
    data
  })
}

// 获取路由权限
export function getRoute(params) {
  return request({
    url: '/digital/seal/flowReview',
    method: 'GET',
    params
  })
}

export function getMode() {
  return request({
    url: '/digital/seal/template',
    method: 'GET'
  })
}