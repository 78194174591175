// 刘志坤-V2.3.0

<template>
  <section class="header">
    <div class="header-box">
      <div class="logo-title">
        <img src="@/assets/images/newlogin/header_logo1.png" class="nav-img" alt="">
        <!-- <span class="nav-title">智慧印章管理后台</span> -->
      </div>
      <section class="action-module">
        <!-- 消息 -->
        <a-dropdown
          :getPopupContainer="(node) => node"
          placement="bottomCenter"
          :trigger="['click']"
        >
          <section @click.prevent="openMenu" class="message">
            <img
              :src="require('@/assets/images/info/info-icon.png')"
              class="message-img"
            />
          </section>
          <template #overlay>
            <a-menu v-if="infoList.length > 0" class="info">
              <a-menu-item
                v-for="item in infoList"
                :key="item.businessType"
                class="info-menu"
              >
                <div class="info-menu-item">
                  <div class="info-item">
                    <div class="info-item-left">
                      <img :src="item.img" class="info-item-img" />
                      <span>{{ item.businessTypeName }}</span>
                    </div>
                    <div class="info-item-time">{{ item.createTime }}</div>
                  </div>
                  <div class="info-item-message">{{ item.content }}</div>
                </div>
              </a-menu-item>
              <div class="check" @click="more()">查看更多 >></div>
            </a-menu>
            <div v-else class="empty">
              <a-empty :image="simpleImage" />
            </div>
          </template>
        </a-dropdown>

        <a-dropdown :getPopupContainer="(node) => node" placement="bottomRight">
          <section class="avtar">
            <img
              width="30"
              height="30"
              :src="avatar || require('@/assets/images/default-avatar.png')"
              alt
            />
            <span
              class="single-wrap"
              :style="{
                width: userName && userName.length > 3 ? '80px' : '',
                display: 'inline-block'
              }"
              >{{ userName || '' }}</span
            >
            <DownOutlined class="enterprise-arrow" />
          </section>
          <template #overlay>
            <a-menu>
              <a-menu-item class="username-menu">
                <div class="username_detail">
                  <img
                    width="36"
                    height="36"
                    :src="
                      avatar || require('@/assets/images/default-avatar.png')
                    "
                    alt
                  />
                  <div
                    :title="userName || ''"
                    class="single-wrap username"
                    :style="{
                      width: userName && userName.length > 3 ? '106px' : ''
                    }"
                  >
                    {{ userName || 'sss' }}
                  </div>
                  <div
                    :title="enterpriseName || ''"
                    class="single-wrap companyname"
                    :style="{
                      width:
                        enterpriseName && enterpriseName.length > 9
                          ? '120px'
                          : ''
                    }"
                  >
                    {{ enterpriseName || 'sss' }}
                  </div>
                </div>
              </a-menu-item>
              <a-menu-item @click="actionBtn('self')">
                <span>个人中心</span>
              </a-menu-item>
              <a-sub-menu
                v-if="isqyWechat"
                class="switch-company"
                key="test"
                title="切换企业"
              >
                <a-menu-item
                  @click="actionBtn('switch', item)"
                  v-for="item in allEnterprise"
                  :class="[enterpriseId === item.id && 'active']"
                  :key="item.id"
                >
                  <p :title="item.company" style="width: 100%;">
                    {{ item.company }}
                  </p>
                  <span class="select-box">
                    <check-outlined
                      v-if="enterpriseId === item.id"
                      class="active"
                    />
                    <i v-else class="iconfont select-arrow icon-dagou"></i>
                  </span>
                </a-menu-item>
              </a-sub-menu>
              <a-menu-item
                v-if="isqyWechat"
                style="height: 41px; line-height: 41px"
                @click="actionBtn('logout')"
              >
                <span>退出</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </section>
    </div>
    <company-modal
      v-model:visible="state.enterpriseVisible"
      :allEnterprise="allEnterprise"
      @select-enterprise="selectEnterprise"
    />
    <notice-modal
      v-model:visible="state.noticeVisible"
      @cancel="state.noticeVisible = false"
    ></notice-modal>
  </section>
</template>

<script>
import { Modal, Empty } from 'ant-design-vue'
import {
  DownOutlined,
  ExclamationOutlined,
  CheckOutlined
} from '@ant-design/icons-vue'
import {
  computed,
  defineComponent,
  reactive,
  createVNode,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { logout } from '@/apis/user'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { SET_ROUTER } from '@/store/router/mutations-type'
import {
  SET_APPLY_PROCESS_LIST,
  SET_PAGE_RELOAD,
  SET_BACK_ROUTER
} from '@/store/globalData/mutations-type'
import { cmsNotice } from '@/utils/utils'
import companyModal from '@/views/User/components/companyModal'
import noticeModal from './component/noticeModal'
import textScroll from './component/textScroll'
import { switchEnterprise } from '@/apis/user'
import { environmentJudge } from '@/utils/utils.js'
import { getHeaderInfo } from '@/apis/infoCenter'

export default defineComponent({
  components: {
    companyModal,
    DownOutlined,
    CheckOutlined,
    noticeModal,
    textScroll
  },
  setup() {
    onMounted(() => {
      var reg = /^(192.168.1.)/
      // if (window.location.hostname !== 'localhost' && !reg.test(window.location.hostname)) {
      //   // 判断非本地server时 http强制转换成https
      //   var targetProtocol = 'https:'

      //   // 添加 meta
      //   // <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
      //   const meta = document.createElement('meta')
      //   meta.setAttribute('http-equiv', 'Content-Security-Policy')
      //   meta.content = 'upgrade-insecure-requests'
      //   document.getElementsByTagName('head')[0].appendChild(meta)

      //   if (window.location.protocol != targetProtocol) {
      //     window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length)
      //   }
      // }
      qyWechat()
      getInfo()
    })
    const router = useRouter()
    const store = useStore()
    if (!store.state.user.userInfo.userName)
      store.commit(
        `user/${SET_USER_INFO}`,
        JSON.parse(localStorage.getItem('yda-admin-userInfo'))
      )
    const userName = computed(() => store.state.user.userInfo.userName)
    const avatar = computed(() => store.state.user.userInfo?.avatar)
    const enterpriseName = computed(
      () => store.state.user.userInfo.result?.enterpriseName
    )
    const enterpriseId = computed(() => store.state.user.userInfo.enterpriseId)
    const allEnterprise = computed(
      () => store.state.user.userInfo.allEnterprise
    )
    const state = reactive({
      noticeVisible: false,
      enterpriseVisible: false,
      userInfo: {},
      navData: computed(() => store.state.router.navMenus)
    })
    const isqyWechat = ref(true)
    const infoList = ref([])

    const simpleImage = ref(Empty.PRESENTED_IMAGE_SIMPLE)
    // 判断环境
    const environment = environmentJudge()
    const qyWechat = () => {
      if (environment != 'OTHERS') {
        isqyWechat.value = false
      }
      // const isMobile = window.navigator.userAgent.match(
      //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      // ) // 是否手机端
      // const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
      // if (isComWx && !isMobile) {
      //   //PC端企业微信
      //   isqyWechat.value = false
      //   return 'com-wx-pc'
      // }
    }
    //清除用印管理内部列表缓存的筛选条件
    const clearCache = () => {
      store.commit('sealManage/CLEAECACHE')
    }

    watch(
      () => store.state.user.userInfo.enterpriseId,
      () => {
        console.log('切换企业触发')
        // data.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId //当前登录的用户id
        // console.log('切换企业后当前登录用户的id', data.staffId)
        getInfo()
      }
    )

    const actionBtn = (type, enterprise) => {
      localStorage.removeItem('checkedRows')
      localStorage.removeItem('paginationParams')

      const actionObj = {
        self: '/personalCenter',
        async switch(enterprise) {
          store.dispatch(`globalData/${SET_PAGE_RELOAD}`, true)
          await selectEnterprise(enterprise)
          store.dispatch(`globalData/${SET_PAGE_RELOAD}`, false)
        },
        logout() {
          Modal.confirm({
            title: '提示',
            content: '确定退出吗？',
            centered: true,
            icon: createVNode(ExclamationOutlined),
            onOk: async () => {
              const res = await logout({ json: 1 })
              if (res.success) {
                console.log('退出登录')
                cmsNotice('success', '退出成功', 1000)
                clearCache()
                store.commit(`user/${SET_USER_INFO}`, {
                  userName: undefined,
                  enterpriseName: undefined
                })
                localStorage.removeItem('yda-admin-userInfo')
                localStorage.removeItem('checkedRows')
                localStorage.removeItem('paginationParams')
                router.push(`/login`)
              }
            }
          })
        }
      }
      if (typeof actionObj[type] === 'string') {
        console.log('geren1zhognin1', router.currentRoute.value.fullPath)
        store.dispatch(
          `globalData/${SET_BACK_ROUTER}`,
          router.currentRoute.value.fullPath
        )
        router.push(actionObj[type])
      } else {
        actionObj[type](enterprise)
      }
    }
    const cacheUserInfo = async () => {
      // 保存用户信息
      localStorage.setItem('yda-admin-userInfo', JSON.stringify(state.userInfo))
      store.commit(`user/${SET_USER_INFO}`, state.userInfo)
      await new Promise((resolve) => {
        store.dispatch(`router/${SET_ROUTER}`, resolve)
      })
      store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
    }
    const validateRedirectPath = () => {
      // 检验跳转地址
      console.log(state.navData)
      let rdRouter
      if (state.navData[0]) {
        if (state.navData[0].children && state.navData[0].children.length) {
          rdRouter = state.navData[0].children[0].path
        } else {
          rdRouter = state.navData[0].path
        }
        localStorage.setItem('reloadPageRouter', rdRouter)
        setTimeout(() => {
          router.replace(rdRouter)
        }, 100)
      }
    }
    const selectEnterprise = async (enterpriseInfo) => {
      // 选择企业
      enterpriseInfo
      const res = await switchEnterprise({ enterpriseId: enterpriseInfo.id })
      const { data } = res
      if (res.success) {
        enterpriseInfo = {
          enterpriseId: res.data.result.enterpriseId,
          enterpriseName: res.data.result.enterpriseName,
          avatar: res.data.avatar,
          enterpriseBindLibawall: res.data.result.enterpriseBindLibawall,
          userName: res.data.userName,
          userId: res.data.result.staffId,
          id: res.data.userId
        }
        userCpData(res.data.userId, res.data.result.enterpriseId)
        // 设置用户信息
        state.userInfo = {
          ...data,
          userName: data.userName,
          userId: data.result.staffId,
          id: data.userId,
          sexName: data.sexName,
          avatar: data.avatar,
          allEnterprise: data.result.allEnterprise,
          mobile: data.mobile,
          nickName: data.nickname,
          enterpriseBindLibawall: data.result.enterpriseBindLibawall,
          isNotInitPassword: data.isNotInitPassword,
          enterpriseName: res.data.result.enterpriseName,
          enterpriseId: data.result.enterpriseId,
          identity: data.identity
        }
        store.commit(`user/${SET_USER_INFO}`, state.userInfo)
        localStorage.setItem(
          'yda-admin-userInfo',
          JSON.stringify(state.userInfo)
        )
      } else {
        enterpriseInfo = {}
      }
      const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
      userInfo && (state.userInfo = userInfo)
      state.userInfo = {
        ...state.userInfo,
        ...enterpriseInfo
      }
      await cacheUserInfo()
      validateRedirectPath()
      cmsNotice('success', '切换成功', 0.5)
    }

    const userCpData = (userId, enterpriseId) => {
      let userCp = JSON.parse(localStorage.getItem('yda-user-data'))
      userCp[userId] = enterpriseId
      localStorage.setItem('yda-user-data', JSON.stringify(userCp))
    }

    // 获取信息列表
    const getInfo = async () => {
      var res = await getHeaderInfo({
        pageIndex: '1',
        pageSize: '5'
      })
      if (res.success) {
        console.log('获取消息列表', res)
        // 是否有未读消息，没有不显示列表
        infoList.value = []
        res.data.forEach((element) => {
          // 塞进不同的图片
          if (
            element.businessType === 1 ||
            element.businessType === 13 ||
            element.businessType === 36
          ) {
            // 待审批/补盖待审批/归档待审批提醒
            element.img = require('@/assets/images/info/icon1.png')
          } else if (
            element.businessType === 7 ||
            element.businessType === 14 ||
            element.businessType === 37
          ) {
            // 审批通过/补盖审批通过/归档审批通过
            element.img = require('@/assets/images/info/icon7.png')
          } else if (
            element.businessType === 8 ||
            element.businessType === 15 ||
            element.businessType === 38
          ) {
            // 审批拒绝/补盖审批拒绝/归档审批拒绝
            element.img = require('@/assets/images/info/icon8.png')
          } else if (
            element.businessType === 22 ||
            element.businessType === 23
          ) {
            // 申请终止/补盖申请终止
            element.img = require('@/assets/images/info/icon22.png')
          } else if (
            element.businessType === 24 ||
            element.businessType === 25
          ) {
            // 终止用印/补盖用印终止
            element.img = require('@/assets/images/info/icon24.png')
          } else if (
            element.businessType === 26 ||
            element.businessType === 27
          ) {
            // 催办审批/催办
            element.img = require('@/assets/images/info/icon26.png')
          } else {
            element.img = require(`@/assets/images/info/icon${element.businessType}.png`)
          }
          infoList.value = res.data
        })
        console.log('1usyiewyutwuy', infoList.value)
      }
    }

    const more = () => {
      // 跳转消息中心
      router.push('/messageCenter')
    }

    const openMenu = () => {
      getInfo()
    }
    return {
      state,
      actionBtn,
      userName,
      enterpriseName,
      avatar,
      allEnterprise,
      selectEnterprise,
      enterpriseId,
      isqyWechat,
      more,
      getInfo,
      infoList,
      simpleImage,
      openMenu
    }
  }
})
</script>

<style lang="scss">
// 选中菜单状态
// .ant-menu-item-selected {
//   background-color: #0A7BFF !important;
//   color: white !important;
// }

// // 设置未选中菜单项鼠标滑过样式
// .ant-menu-item-active {
//   background-color: #0A5DBE !important;
//   color: #8292A5 !important;
// }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f2f3f7;
  min-width: 750px;
  box-shadow: 0px 2px 20px 0px rgba(97, 131, 155, 0.06);
  z-index: 999;
}
.header-box {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(76, 76, 76, 0.1);
  .logo-title {
    // margin-left: 16px;
    line-height: 48px;
    text-align: center;
    width: 208px;
    .nav-img {
      // width: 100%;
      height: 33px;
      display: inline-block;
      // margin-right: 8px;
      vertical-align: middle;
    }
    .nav-title {
      color: #001529;
      font-size: 14px;
    }
  }
  .action-module {
    color: #8b92b8;
    display: flex;
    line-height: 48px;
    .notice {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 40px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;

      .horn-img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        object-fit: cover;
      }
      > div {
        width: 406px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }
    .avtar {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      margin-right: 23px;
      cursor: pointer;
      .enterprise-arrow {
        font-size: 14px;
        margin-left: 10px;
        display: inline-block;
      }
      .ant-dropdown {
        width: 176px;
      }
      .ant-dropdown-menu-item {
        &:hover {   //顶部个人中心下拉文字颜色
          // background: #ffebe8;
          color: #0A7BFF;
          background: #f0f0f0;
        }
      }
      .username-menu {
        &:hover {
          background: none;
          cursor: default;
        }
        .username_detail {
          display: flex;
          flex-direction: column;
          align-items: center;
          .username {
            width: 106px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: center;
            color: #333333;
            line-height: 20px;
            margin-bottom: 4px;
          }
          .companyname {
            width: 120px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: rgba(51, 51, 51, 0.45);
            line-height: 17px;
          }
          img {
            border-radius: 50%;
            vertical-align: middle;
          }
        }
        img {
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
      .username-menu.ant-dropdown-menu-item {
        border-bottom: 1px #d7dbe0 solid;
        height: 113px;
        font-weight: 500;
        color: #333333;
        cursor: default;
      }
      .ant-dropdown-menu-submenu-popup {
        // width: 220px;
        max-height: 400px;
        overflow-y: auto;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        .ant-dropdown-menu-item {
          display: flex;
          justify-content: space-between;
          height: 40px;
          padding-left: 10px;
          padding-right: 10px;
        }
        .active {
          color: #0A7BFF;
          // background-color: #d01837;
        }
        .active::after {
          font-size: 12px;
          font-weight: 700;
        }
        > ul {
          margin: 0;
        }
      }
      .switch-company {
        height: 36px;
        line-height: 36px;
        .ant-dropdown-menu-submenu-title {
          &:hover {
            background: #f0f0f0;
            color: #0A7BFF;
            .ant-dropdown-menu-submenu-arrow-icon {
              color: #0A7BFF;
            }
          }
        }
      }
      > img {
        object-fit: cover;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        vertical-align: middle;
        margin-right: 8px;
      }
      > span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .enterprise {
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 26px;
      font-style: 16px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      > span {
        display: inline-block;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      .enterprise-arrow {
        font-size: 14px;
        margin-left: 10px;
        display: inline-block;
        margin-top: 15px;
      }
      .ant-dropdown {
        max-height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .active {
          color: #ffffff;
          background-color: #0A7BFF;
        }
        .select-arrow {
          font-size: 20px;
          display: inline-block;
        }
      }
      .select-box {
        width: 10px;
        margin-left: 26px;
      }
      .ant-dropdown-menu-item {
        display: flex;
        justify-content: space-between;
        &:hover {
          background-color: #ffebe8;
          color: #0A7BFF;
        }
      }
    }
    .ant-dropdown {
      border-radius: 6px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      background: #fff;
      .ant-dropdown-menu {
        padding: 0;
        background: #fff;
        box-shadow: 0px 0 0 0px rgba(0, 0, 0, 0.15);
      }
    }
    .avtar .ant-dropdown-menu-item {
      height: 36px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      line-height: 36px;
      color: #666666;
      padding-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 12px;
      padding-right: 13px;
    }
    .action-btns {
      padding-left: 20px;
      .btn {
        padding: 0 16px;
        height: 40px;
        opacity: 1;
        border: 1px solid #8b92b8;
        border-radius: 23px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: right;
        color: #8b92b8;
        margin-right: 14px;
        background: transparent;
      }
      .logout-btn {
        > img {
          vertical-align: middle;
          width: 13px;
          margin-right: 3px;
          transform: translateY(-1px);
        }
      }
    }
  }
}

:deep(.ant-dropdown) {
  height: 100%;
}
:deep(.ant-dropdown-content) {
  height: 100%;
}
</style>

<style lang="scss" scoped>
.message {
  background-color: #ffffff;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
  display: flex;
  .message-img {
    width: 16px;
    height: 16px;
    position: relative;
    top: 0;
  }
  :deep(.ant-dropdown) {
    margin-top: 8px;
    padding: 8px 0;
    width: 340px;
    left: calc(100vw - 364px) !important;
  }
  .info {
    :deep(.ant-dropdown-menu-item) {
      padding: 0 16px !important;
    }
    :deep(.ant-dropdown-menu-item) {
      padding: 0;
    }
    .info-menu {
      background-color: #ffffff;
      .info-menu-item {
        padding: 16px 0 !important;
        border-bottom: 1px solid #f1f1f2;
      }
    }
    .info-menu:last-of-type > .info-menu-item {
      border-bottom: none;
    }
    .info-item {
      display: flex;
      justify-content: space-between;
      .info-item-left {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 500;
      }
      .info-item-img {
        width: 20px;
        height: 20px;
        vertical-align: -6px;
        margin-right: 6px;
      }
      .info-item-time {
        color: #999999;
        font-size: 13px;
      }
    }
    .info-item-message {
      color: #666666;
      font-size: 13px;
      margin-top: 4px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .check {
      color: #1890ff;
      text-align: center;
      margin-bottom: 8px;
    }
  }
  .empty {
    width: 340px;
    height: 252px;
    padding-top: 80px;
    :deep(.ant-empty-normal) {
      margin: 0;
    }
  }
}
</style>
