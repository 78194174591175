import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const globalData = {
  namespaced: true,
  getters,
  state: {
    applyProcessList: [],
    isFetchApplyProcess: false,
    applyUpdate: [], // 让用印管理刷新
    rootSize: 16, // rem适配
    pageReload: false, // 切换企业的spinning
    backRouter:"",
    lastDepartmentInfo: { n: 0 }, // 记录上一次的组织架构信息
  },
  mutations,
  actions
}

export default globalData
